
.MuiSlider-colorPrimary{
  color: white !important;
  height: 2px !important;
  width: 83% !important;
}

.MuiSlider-thumbColorPrimary{
  width: 10px !important;
  height: 10px !important;
}

.slider-box-container{
  display: flex;
    justify-content: space-around;
}

.slider-text{
  font-weight: bold;
}

.slider-text-container{
  font-size: 14px;
  /* text-align: unset; */
  align-items: center;
  display: flex;
  padding-bottom: 4px;
  min-width: 100px;
  justify-content: center;
}