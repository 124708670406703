
.MuiSlider-colorPrimary{
  color: white !important;
  height: 2px !important;
  width: 83% !important;
}

.MuiSlider-thumbColorPrimary{
  width: 10px !important;
  height: 10px !important;
}


.doughnut-container{
  width: 100%;
  height: 300px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
}