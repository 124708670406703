.App {
  text-align: center;
  background-color: #000e37;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.charts-container{
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.chart-container{
  width: 46%;
  padding: 2%;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000d37;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 50px 100px;
}

.App-link {
  color: #61dafb;
}

.proposal-selector{
  background: transparent;
  color: white;
  border: none;
  border-bottom: solid;
  font-size: 20px;
  margin: 0px 30px;
}

.proposal-selector:focus-visible{
  outline: none;
}

.chart-title{
  font-size: 24px;
  font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    color: rgb(255, 255, 255);
    letter-spacing: -0.005em;
}

.proposal-selector-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.proposal-selector-line{
  height: 2px;
  background: white;
  width: 100%;
  opacity: 0.5;
}

.title-bar{
  width: 61%;
  background: #ffff;
  opacity: 0.5;
  height: 2px;
  margin-left: 20px;
}

.chart-desc{
  text-align: initial;
  font-size: 14px;
  min-height: 64px;
  padding: 20px  0px ;
  font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    line-height: 1.6;
    color: rgb(255, 255, 255);
    
    text-size-adjust: 100%;
}

.title{
  font-size: 32px;
  padding-left: 10px;
  font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    color: rgb(255, 255, 255);
    letter-spacing: -0.005em;
}

.title-banner{
  width: 100%;
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
    padding-bottom: 24px;
    /* padding-right: 72px; */
    padding-left: 48px;
}

.title-container{
    display: flex;
    color: white;
    /* width: 35%; */
    text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media(max-width:760px){
  .chart-container{
    width: 100%;
    padding: 0%;
  }
}

@media(max-width:1200px){
  .App-header {
    padding: 10px;
  }
}
